
/** @type {import('vue-router').NavigationGuardWithThis} */
export async function billingGuard({ to, interfaceStore }) {
  const UNAUTHORIZED_ROLE = 'member'
  const { getSelectedProjectObject } = interfaceStore
  
  if(getSelectedProjectObject && to.fullPath.includes('billing')) {
    if(getSelectedProjectObject.role === UNAUTHORIZED_ROLE) {
      return '/'
    }
  }
}