import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from 'pinia';

import admin_routes from "./admin_routes"
import public_routes from "./public_routes"
import auth_protected_routes from "./auth_protected_routes";
import beforeEachRoute from './hooks/beforeEachRoute'
import { useInterfaceStore } from '../stores/interface.ts'

const pinia = createPinia();
const interfaceStore = useInterfaceStore(pinia);


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...public_routes, 
    ...auth_protected_routes,
    ...admin_routes,
  ],
});

router.beforeEach( async (to, from, next) => {
  await beforeEachRoute({
    to,
    from,
    next,
    interfaceStore
  })
});

export default router;
