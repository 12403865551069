const SupportPage = () => import("../views/SupportPage.vue");
const CheckoutPage = () => import("../views/CheckoutPage.vue");
const HomePage = () => import("../views/HomePage.vue");
const ForgotPasswordPage = () => import("../views/Password/ForgotPasswordPage.vue");
const ResetPasswordView = () => import("../views/Password/ResetView.vue");

const CallbackView = () => import("../views/CallbackView.vue");
const SignupPage = () => import('../views/SignupView.vue')

interface IMetaObject {
  layout?: string,
  is_auth_protected?: boolean,
  only_admin?: boolean,
}

interface IRoute {
  path: string,
  name: string,
  component: object,
  meta?: IMetaObject,
  props?: unknown,
  beforeEnter?: Function
}

const public_routes: Array<IRoute> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      layout: "LayoutDefault",
    },
  },
  // {
  //   path: '/signup',
  //   name: 'SignupView',
  //   component: SignupPage,
  //   meta: {
  //     layout: 'LayoutSimple'
  //   }
  // },
  {
    path: "/support",
    name: "SupportPage",
    component: SupportPage,
  },
  {
    path: "/checkout",
    name: "CheckoutPage",
    component: CheckoutPage,
    meta: {
      layout: "LayoutSimple"
    }
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
    meta: { 
      layout: "LayoutSimple"
    }
  },

  {
    path: "/reset-password/:token",
    name: "ResetPasswordPage",
    component: ResetPasswordView,
    meta: { 
      layout: "LayoutSimple"
    }
  }

]

export default public_routes;